<template>
  <div class="p-5">
    <div class="container mx-auto">
      <h1 class="text-6xl font-bold text-secondary mb-3">
        Ooops... Error 404
      </h1>
      <p class="text-4xl">Lo sentimos, pero la página que busca no existe.</p>
      <router-link
        class="text-2xl text-white font-semibold bg-primary p-6 mt-6 inline-block rounded-lg"
        :to="{ name: 'Home' }"
      >
        Regresar a la Pagina de Inicio
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
