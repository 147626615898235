import axios from 'axios';

const http = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

http.defaults.withCredentials = true;

export default http;
