<template>
  <template v-if="!type">
    <div v-tab-inside="{focusFirst: true, prevFocused: true}" class="relative z-10 cursor-auto" @click.stop="() => null" @keydown.esc.exact="close('esc')">
      <slot />
    </div>
  </template>
  <template v-else-if="type === 'actions'">
    <div
      v-tab-inside
      class="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg w-full mx-auto"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Deactivate account
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <slot :close="close" />
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="close()">
          Deactivate
        </button>
        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="close()">
          Cancel
        </button>
      </div>
      <div
        v-bind="$attrs"
        class="relative border bg-white rounded-lg cursor-default"
      >
        <template v-if="!header">
          <button
            type="button"
            class="absolute top-0 right-0 h-6 w-6 flex items-center justify-center overflow-hidden"
            data-dismiss="modal"
            aria-label="Close"
            @click="close('click')"
          >
            <span class="transform -translate-y-0.5" aria-hidden="true">×</span>
          </button>
        </template>
      </div>
    </div>
  </template>
</template>

<script>
import { TabInside } from '@/directives/TabInside';

export default {
  name: 'TwModalContent',
  directives: { TabInside },
  inheritAttrs: false,
  props: {
    type: String,
  },
  emits: ['close'],
  setup(props, context) {
    const close = () => context.emit('close', true);
    return { close };
  },
};
</script>

<style></style>
