import { Directive, DirectiveBinding, Fn, MixinComponent } from '../types';
import { addEventListener, removeEventListeners } from './events';

function actionOnTab(el: HTMLElement, focusFirst: Boolean = true): Fn<void> {
  const f = focusables(el);
  if (focusFirst) f[0].focus();
  return (e: KeyboardEvent) => {
    if (e.code !== 'Tab') return;
    e.preventDefault();
    const active = <HTMLElement>document.activeElement;
    const key = f.indexOf(active);
    const last = f.length - 1;
    if (e.shiftKey) { // SHIFT + TAB = previous or last
      f[key === 0 ? last : key - 1].focus();
    } else { // TAB = next or first
      f[key === last ? 0 : key + 1].focus();
    }
  };
}

function focusables(el: HTMLElement) {
  const queryString = 'a, button, details, input, select, textarea, [tabindex]:not([tabindex="-1"]), [contenteditable]';
  const els = <HTMLElement[]>Array.from(el.querySelectorAll(queryString));
  return els.filter(el => !el.hasAttribute('disabled')).sort((a, b) => (
    Number(a.getAttribute('tabindex') || 0) - Number(b.getAttribute('tabindex') || 0)
  ));
}

const prev: Map<HTMLElement, HTMLElement> = new Map();

const addListener = (el: HTMLElement, binding: DirectiveBinding<Options>) => {
  removeListener(el);
  const action = actionOnTab(el, binding.value?.focusFirst !== false);
  addEventListener(el, 'keydown', action);
  if (binding.value?.prevFocused) prev.set(el, <HTMLElement>document.activeElement);
};

const removeListener = (el: HTMLElement) => {
  removeEventListeners(el, 'keydown');
  const prevFocused = prev.get(el);
  if (prevFocused) {
    prevFocused.focus();
    prev.delete(el);
  }
};

export const TabInside: Directive<Options> = {
  mounted: addListener,
  updated(el, binding) {
    if (binding.value !== binding.oldValue) addListener(el, binding);
  },
  beforeUnmount: removeListener,
};

export const directive = TabInside;

export const mixin: MixinComponent = {
  directives: { TabInside },
};

/** Types */
type Options = ObjectOption | null;
interface ObjectOption {
  focusFirst?: Boolean;
  prevFocused?: Boolean;
}
