<template>
  <VueHeader />
  <main class="min-h-main">
    <router-view />
  </main>
  <VueFooter />
</template>

<script lang="ts">
import VueHeader from '@/components/Header.vue';
import VueFooter from '@/components/Footer.vue';

export default {
  name: 'ContainerBase',
  components: {
    VueHeader,
    VueFooter,
  },
};
</script>
