import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import { required, email, min, max, confirmed, alpha_spaces, numeric, alpha_num, size } from '@vee-validate/rules';
import { setLocale } from '@vee-validate/i18n';
import es from '@vee-validate/i18n/dist/locale/es.json';

configure({
  generateMessage: localize({
    es,
  }),
});

setLocale('es');

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('confirmed', confirmed);
defineRule('alpha_spaces', alpha_spaces);
defineRule('numeric', numeric);
defineRule('alpha_num', alpha_num);
defineRule('size', size);
