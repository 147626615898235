const store: ElementsEventsActions = new Map();

export function addEventListener(el: HTMLElement, name: Event, action: Action, options?: Options) {
  const event = name.replace(/\..*/, '');
  el.addEventListener(event, action, options);
  const events: EventsActions = store.get(el) || new Map();
  const actions: Actions = events.get(name) || [];
  actions.push(action);
  events.set(name, actions);
  store.set(el, events);
}

export function removeEventListeners(el: HTMLElement, name: Event, options?: Options) {
  const events = store.get(el);
  if (!events) return;
  const actions: Actions = events.get(name) || [];
  const event = name.replace(/\..*/, '');
  actions.forEach(action => {
    el.removeEventListener(event, action, options);
  });
  events.delete(name);
  if (!events.size) store.delete(el);
}

// Types

type Action = EventListener;
type Actions = Action[];
type Event = string;
type EventsActions = Map<Event, Actions>;
type ElementsEventsActions = Map<HTMLElement, EventsActions>;
type Options = boolean | EventListenerOptions;
