<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-moveout="hideResults" class="w-full relative">
    <div class="relative">
      <input
        id="equipment-query"
        v-model="query"
        class="shadow-lg appearance-none rounded w-full py-4 pl-3 pr-4 text-gray-700 leading-tight focus:shadow-outline"
        type="text"
        placeholder="Buscar maquinaria, marcas y más..."
        :autofocus="autofocus"
        autocomplete="off"
        @focus="showResults = true"
        @keyup.enter.stop="onEnter()"
        @keyup.down="nextOption()"
        @keyup.up="previousOption()"
        @keyup.esc="selected >= 0 ? selected = -1 : hideResults()"
      />
      <div class="absolute right-0 top-0 h-full grid place-content-center mr-3 cursor-pointer" @click="goToResultsPage()">
        <r-icon type="search" class="h-4 text-primary" />
      </div>
    </div>
    <div
      v-show="showResults && query.length > 2"
      class="results absolute bottom-0 left-0 right-0 transform translate-y-full bg-white border border-gray-600 rounded z-50"
    >
      <router-link
        v-for="(hit, index) in hits"
        :key="hit.slug"
        tabindex="-1"
        class="flex items-center p-4"
        :class="index == selected ? 'bg-gray-200 hover:bg-gray-300' : 'hover:bg-gray-100'"
        :to="{ name: 'equipment', params: { slug: hit.slug } }"
        @click="showResults = false"
      >
        <r-icon type="chevron-right" class="h-5" />
        <span v-html="hit._highlightResult.name.value"></span>
        <span class="ml-4 text-gray-500">modelo:</span>
        <span v-html="hit._highlightResult.model.value"></span>
        <small class="ml-auto" v-html="hit.subcategories[0]"></small>
      </router-link>
      <div
        v-show="!searching && hits.length === 0"
        class="text-center p-4 hover:bg-gray-200 w-full"
      >
        No hay resultados para <strong>"{{ query }}"</strong>
      </div>
      <div v-show="searching" class="flex justify-center w-full p-4 hover:bg-gray-200">
        <r-icon type="spinner" class="h-5 text-chamb-500" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import algoliaClient from '@/common/algoliaClient';
import { Moveout } from '@/directives/Moveout';
import { AdvancedData } from './types';

export default defineComponent({
  name: 'SearchAdvanced',
  directives: { Moveout },
  props: {
    autofocus: Boolean,
  },
  data: () => ({
    client: algoliaClient.initIndex('equipments'),
    query: '',
    backend: process.env.VUE_APP_BACKEND,
    hits: [],
    showResults: false,
    searching: false,
    maxHits: 5,
    selected: -1,
  } as AdvancedData),
  computed: {
    canSearch(): boolean {
      return this.query?.length > 2;
    },
  },
  watch: {
    query(value: string) {
      if (value && this.canSearch) this.searchIndex();
      else this.hits = [];
    },
  },
  methods: {
    async searchIndex() {
      if (!this.searching) {
        if (!this.showResults) this.showResults = true;
        this.searching = true;
        try {
          const { hits } = await this.client.search(this.query, { hitsPerPage: this.maxHits });
          if (this.canSearch) this.hits = hits;
        } catch (error) {
          console.error(error);
        }
        this.searching = false;
      }
    },
    onEnter() {
      if (!this.canSearch) return;
      if (this.selected < 0) {
        this.goToResultsPage();
      } else {
        this.goToProductPage();
      }
    },
    hideResults() {
      this.showResults = false;
      this.selected = -1;
    },
    goToResultsPage() {
      this.hideResults();
      const query = this.query;
      this.$router.push({ name: 'search', params: { query } });
    },
    goToProductPage() {
      const slug = this.hits?.[this.selected]?.slug || '';
      this.$router.push({ name: 'equipment', params: { slug } });
      this.hideResults();
    },
    nextOption() {
      this.selected = this.selected < this.hits.length - 1 ? this.selected + 1 : 0;
    },
    previousOption() {
      this.selected = this.selected > 0 ? this.selected - 1 : this.hits.length - 1;
    },
  },
});
</script>

<style>
</style>
