
import { computed, defineComponent } from '@vue/runtime-core';
import { getBackgroundImages } from '@/utils';

export default defineComponent({
  name: 'BgImage',
  props: {
    src: String,
    over: [String, Array],
    under: [String, Array],
    empty: String,
  },
  setup(props) {
    const backgroundImage = computed(() => getBackgroundImages(props));
    return { backgroundImage };
  },
});
