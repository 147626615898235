import { Equipment } from '@/store/models/equipment';

const api = {
  category: {
    index: 'api/categories',
    show: (name: string) => `api/categories/${name}`,
    equipment: (name: string) => `api/categories/${name}/equipments`,
  },
  equipment: {
    index: 'api/equipments',
    show: (name: string) => `api/equipments/${name}`,
    itemsByDate: (eq: Equipment) => `api/equipments/${eq.equipment}/${eq?.startDate?.value}/${eq?.endDate?.value}`,
  },
};

export default api;
