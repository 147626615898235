
import { defineComponent } from '@vue/runtime-core';
import algoliaClient from '@/common/algoliaClient';
import { Moveout } from '@/directives/Moveout';
import { AdvancedData } from './types';

export default defineComponent({
  name: 'SearchAdvanced',
  directives: { Moveout },
  props: {
    autofocus: Boolean,
  },
  data: () => ({
    client: algoliaClient.initIndex('equipments'),
    query: '',
    backend: process.env.VUE_APP_BACKEND,
    hits: [],
    showResults: false,
    searching: false,
    maxHits: 5,
    selected: -1,
  } as AdvancedData),
  computed: {
    canSearch(): boolean {
      return this.query?.length > 2;
    },
  },
  watch: {
    query(value: string) {
      if (value && this.canSearch) this.searchIndex();
      else this.hits = [];
    },
  },
  methods: {
    async searchIndex() {
      if (!this.searching) {
        if (!this.showResults) this.showResults = true;
        this.searching = true;
        try {
          const { hits } = await this.client.search(this.query, { hitsPerPage: this.maxHits });
          if (this.canSearch) this.hits = hits;
        } catch (error) {
          console.error(error);
        }
        this.searching = false;
      }
    },
    onEnter() {
      if (!this.canSearch) return;
      if (this.selected < 0) {
        this.goToResultsPage();
      } else {
        this.goToProductPage();
      }
    },
    hideResults() {
      this.showResults = false;
      this.selected = -1;
    },
    goToResultsPage() {
      this.hideResults();
      const query = this.query;
      this.$router.push({ name: 'search', params: { query } });
    },
    goToProductPage() {
      const slug = this.hits?.[this.selected]?.slug || '';
      this.$router.push({ name: 'equipment', params: { slug } });
      this.hideResults();
    },
    nextOption() {
      this.selected = this.selected < this.hits.length - 1 ? this.selected + 1 : 0;
    },
    previousOption() {
      this.selected = this.selected > 0 ? this.selected - 1 : this.hits.length - 1;
    },
  },
});
