<template>
  <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 md:grid-cols-3">
    <CategoryCard v-for="category in categories" :key="category.slug" :category="category" />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue';
import { getCategories, refCategories } from '@/store/categories';
import CategoryCard from '@/components/Category/Card.vue';

export default defineComponent({
  components: {
    CategoryCard,
  },
  setup() {
    onBeforeMount(getCategories());
    const categories = refCategories();
        
    return { categories };
  },
});
</script>
