<template>
  <tw-modal :model-value="modal === 'login'" bg-close @update:modelValue="closeModal()">
    <div class="cursor-pointer" @click="closeModal()">
      <tw-container class="relative" maxw="max-w-3xl">
        <div class="relative cursor-auto z-10 border-0 h-full bg-white rounded-lg overflow-hidden flex" @click.stop="() => null">
          <bg-image
            class="hidden md:block w-3/5 bg-cover bg-center"
            :src="require('@/assets/INICIARSESION-IMG.jpg')"
          />
          <div class="flex-grow h-full flex flex-col items-center justify-center p-14">
            <button
              type="button"
              class="absolute top-0.5 right-0.5 h-6 w-6 flex items-center justify-center overflow-hidden"
              data-dismiss="modal"
              aria-label="Close"
              :tabindex="1"
              @click="closeModal()"
            >
              <span class="transform -translate-y-0.5" aria-hidden="true">×</span>
            </button>
            <span class="uppercase font-secondary font-bold text-secondary text-3xl pt-6">Iniciar Sesión</span>
            <div class="font-medium space-x-3 py-1">
              <span class="text-secondary text-xs tracking-tighter font-bold">¿Aún no eres miembro?</span>
              <button type="button" class="text-primary underline px-1 text-xs tracking-tighter font-bold" :tabindex="1" @click.stop="openModal('register')">
                Regístrate aquí
              </button>
            </div>
            <div class="w-full max-w-md mx-auto py-1">
              <form class="py-6" @submit.prevent.stop="submit()">
                <p v-if="countdown" class="text-red-500">
                  {{ countdown.msg }}
                </p>
                <p v-else-if="!!errors" class="text-red-500">
                  {{ errors?.errors?.email[0] }}
                </p>
                <label class="block py-2 space-y-2">
                  <span class="block text-xs ml-3 tracking-tighter text-secondary">
                    Correo electrónico:
                  </span>
                  <input
                    id="email"
                    v-model="form.email"
                    class="shadow appearance-none border rounded w-full py-2 px-3 placeholder-gray-700 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline"
                    type="email"
                    name="email"
                    placeholder="Ingresa el correo"
                    required
                  />
                </label>
                <label class="block py-2 space-y-2">
                  <span class="block text-xs ml-3 tracking-tighter text-secondary">
                    Contraseña:
                  </span>
                  <input
                    id="password"
                    v-model="form.password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 placeholder-gray-700 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline"
                    type="password"
                    name="password"
                    placeholder="Escribe la contraseña"
                    required
                  />
                </label>
                <div class="text-right">
                  <button type="button" class="inline-block align-baseline text-xs ml-3 tracking-tighter text-chamb-500 hover:text-chamb-800 p-1" @click.stop="openModal('forgot')">
                    ¿Olvidaste la contraseña?
                  </button>
                </div>
                <div class="py-6">
                  <promise-button id="iniciar-sesion" type="submit" class="inline-block uppercase ml-auto font-bold tracking-wide font-secondary bg-secondary text-white hover:bg-primary" :promise="promise" disable-on-promise>
                    <template #default>
                      Iniciar sesión
                    </template>
                    <template #fallback>
                      Iniciando...
                    </template>
                  </promise-button>
                </div>
                <div class="text-center">
                  <p class="text-gray-700 text-xs tracking-tighter">
                    Al iniciar sesión, aceptas los
                    <span class="text-secondary text-xs tracking-tighter font-bold">Términos y Condiciones</span>
                    , así como el
                    <span class="text-secondary text-xs tracking-tighter font-bold">Aviso de Privacidad</span> de Rentalo
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </tw-container>
    </div>
  </tw-modal>
</template>

<script lang="ts">
import { LoginForm } from '@/store/models/security';
import { clearErrors, closeModal, login, useErrors, useModal, openModal } from '@/store/security';
import { defineComponent, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';

interface LoginRetryCountDown {
  msg: string;
  timer: number;
  counter: number;
}

export default defineComponent({
  name: 'LoginModal',
  setup() {
    const countdown = ref<LoginRetryCountDown | null>(null);
    const errors = useErrors();
    const form = ref<LoginForm>({
      email: null,
      password: null,
    });
    const modal = useModal();
    const router = useRouter();
    const route = useRoute();

    const promise = ref<Promise<any>|null>(null);
    const submit = () => {
      if (countdown.value) {
        clearInterval(countdown.value.timer);
        countdown.value = null;
      }
      return promise.value = login(form.value).then(logged => {
        if (logged) {
          closeModal();
          router.push(route.fullPath);
        }
      }).catch(({response}) => {
        if (response?.status === 429) {
          clearErrors();
          const msg = response?.data?.errors?.email?.[0];
          countdown.value = {
            msg,
            timer: setInterval(() => {
              if (!countdown.value) return;
              if (countdown.value.counter > 1) {
                countdown.value.counter--;
                countdown.value.msg = countdown.value.msg.replace(/\d+/, `${countdown.value.counter}`);
              } else {
                clearInterval(countdown.value.timer);
                countdown.value = null;
              }
            }, 1000),
            counter: Number(msg.replace(/\D+/g, '')),
          };
        }
      }).finally(() => {
        promise.value = null;
      });
    };

    return { closeModal, countdown, form, modal, errors, openModal, promise, submit };
  },
});
</script>

<style></style>
