<template>
  <teleport to="#modals">
    <transition v-bind="scale">
      <div v-if="show" class="fixed inset-0 bg-black bg-opacity-50 z-30 flex flex-col justify-center items-center overscroll-none">
        <div v-if="bgClose" class="absolute inset-0 cursor-pointer" @click="close()" />
        <div class="max-w-modal w-full cursor-pointer" @click="bgClore && close()">
          <modal-content :type="type" @close="close">
            <slot :close="close" />
          </modal-content>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import ModalContent from './Content';
import { scale } from '@/components/Tw/Transitions/transitions';

export default {
  name: 'TwModal',
  components: { ModalContent },
  inheritAttrs: false,
  props: {
    type: String,
    modelValue: Boolean,
    bgClose: Boolean,
    header: Boolean,
  },
  emits: ['update:modelValue'],
  setup: () => ({ scale }),
  data() {
    return {
      show: this.modelValue,
    };
  },
  watch: {
    modelValue(value) {
      if (this.show !== value) this.show = value;
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit('update:modelValue', false);
    },
  },
};
</script>

<style>

</style>
