<template>
  <footer class="bg-container bg-cover footer-bg-01 block">
    <ContactSection v-if="contact" />
    <section class="bg-secondary bg-opacity-90 text-white py-6 pt-4 xs:pb-28 pb-24 md:pb-4">
      <tw-container class="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-5">
        <div class="sm:col-span-1 flex flex-col justify-center">
          <div class="mx-auto flex">
            <div class="min-w-12 mr-3">
              <r-icon type="logo" class="h-12" />
            </div>
            <div class="flex flex-col">
              <div class="font-secondary font-bold text-3xl mt-1 mb-5">RENTALO</div>
              <span>Morelos 1076 Ote, Col. Centro,</span>
              <span>Monterrey Nuevo León, México.</span>
              <span>CP 64000.</span>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1 flex flex-col justify-center uppercase">
          <div class="mx-auto flex flex-col">
            <router-link :to="{name: 'profile'}">
              Mi cuenta
            </router-link>
            <template v-if="isAuthenticated()">
              <button class="uppercase" @click="logout()">
                Cerrar sesión
              </button>
            </template>
            <template v-else>
              <button class="uppercase" @click="openModal('login')">
                Iniciar sesión
              </button>
              <button :to="{}" class="uppercase" @click="openModal('register')">
                Crear cuenta
              </button>
            </template>
            <router-link :to="{name: 'Quote'}">
              Carrito
            </router-link>
            <router-link :to="{ name : 'Faqs' }">
              Ayuda
            </router-link>
          </div>
        </div>
        <div class="sm:col-span-2 lg:col-span-1 flex flex-col justify-center uppercase font-secondary text-center">
          <div class="mx-auto flex flex-col space-y-2">
            <tw-button
              href="#"
              class="bg-primary text-white hover:bg-white hover:text-primary"
              @click="startNow()"
            >
              Comienza ahora mismo
            </tw-button>
            <tw-button href="tel:8112345678" class="bg-primary text-white hover:bg-white hover:text-primary">
              (81) 1234-5678
            </tw-button>
            <tw-button href="mailto:correo@rentalomx.com?subject=Información Rentalo" class="bg-primary text-white hover:bg-white hover:text-primary">
              correo@rentalomx.com
            </tw-button>
          </div>
        </div>
        <div class="hidden md:block"></div>
        <div class="sm:col-span-2 text-sm text-center uppercase mt-2 divide-x flex justify-center">
          <span class="px-4">Rentalo 2020</span>
          <span class="px-4">Términos y condiciones</span>
          <span class="px-4">Aviso de privacidad</span>
        </div>
      </tw-container>
    </section>
  </footer>
</template>

<script>
import ContactSection from './ContactSection';
import { openModal, isAuthenticated, logout } from '../store/security';

export default {
  components: {
    ContactSection,
  },
  setup: () => ({openModal, isAuthenticated, logout}),
  computed: {
    contact() {
      return this.$route?.meta?.footer?.contact ? true : false;
    },
  },
  methods: {
    startNow() {
      if(isAuthenticated()) {
        return;
      }
      openModal('register');
    },
  },
};
</script>

<style>
.footer-bg-01 {
  background-image: url('../assets/footer-bg-01.png');
}
</style>
