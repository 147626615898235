
import { closeModal, sendResetLinkEmail, useModal, useErrors, openModal } from '@/store/security';
import { defineComponent } from 'vue';
import { ResetPasswordForm } from '@/store/models/security';

export interface ForgotData {
  form: ResetPasswordForm;
  promise: Promise<any> | null;
  message: string | null;
}

export default defineComponent({
  name: 'ForgotModal',
  setup() {
    const modal = useModal();
    const errors = useErrors();
    return { closeModal, modal, errors, openModal };
  },
  data: () => ({
    form: {
      email: null,
    },
    promise: null,
    message: null,
  } as ForgotData),
  methods: {
    async submit() {
      this.promise = sendResetLinkEmail(this.form);
      const response = await this.promise;
      if(response.status === 200) {
        this.message = response.data.message;
      }
      this.promise = null;
    },
  },
});
