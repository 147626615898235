<template>
  <tw-modal :model-value="modal === 'forgot'" bg-close @update:modelValue="closeModal()">
    <div class="cursor-pointer" @click="closeModal()">
      <tw-container class="relative" maxw="max-w-4xl">
        <div class="relative cursor-auto z-10 h-full bg-gray-200 rounded-lg border overflow-hidden flex" @click.stop="() => null">
          <bg-image
            class="hidden md:block w-2/5 bg-cover bg-center"
            :src="require('@/assets/header-bg-02.png')"
          />
          <div class="flex-grow h-full flex flex-col items-center justify-center p-6">
            <button
              type="button"
              class="absolute top-0.5 right-0.5 h-6 w-6 flex items-center justify-center overflow-hidden"
              data-dismiss="modal"
              aria-label="Close"
              :tabindex="1"
              @click="closeModal()"
            >
              <span class="transform -translate-y-0.5" aria-hidden="true">×</span>
            </button>
            <span class="uppercase font-secondary font-medium text-secondary text-3xl pt-6">Restaurar Contraseña</span>
            <div class="font-medium space-x-3 py-1">
              <span class="text-secondary">¿Aún no eres miembro?</span>
              <button type="button" class="text-primary underline px-1" :tabindex="1" @click.stop="openModal('register')">
                Regístrate aquí
              </button>
            </div>
            <div class="w-full max-w-md mx-auto py-1">
              <form v-if="!message" class="py-6" @submit.prevent.stop="submit()">
                <p v-if="errors" class="text-red-500">
                  {{ errors.errors.email[0] }}
                </p><div class="mb-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
                    Confirmar correo electrónico
                  </label>
                  <input
                    id="email"
                    v-model="form.email"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="email"
                    name="email"
                    placeholder="Correo"
                    required
                  />
                </div>
                <div class="text-right">
                  <button type="button" class="inline-block align-baseline font-bold text-sm text-chamb-500 hover:text-chamb-800 p-1" @click.stop="openModal('login')">
                    Iniciar sesión
                  </button>
                </div>
                <div class="py-6">
                  <promise-button type="submit" class="btn-secondary" :promise="promise" disable-on-promise>
                    <template #default>
                      Enviar enlace para restaurar la contraseña
                    </template>
                    <template #fallback>
                      Buscando...
                    </template>
                  </promise-button>
                </div>
              </form>
              <div v-else class="px-8 pt-6 pb-8">
                {{ message }}
                <tw-button class="bg-primary hover:bg-secondary text-white block mx-auto" @click.stop="closeModal()">
                  Cerrar
                </tw-button>
              </div>
            </div>
          </div>
        </div>
      </tw-container>
    </div>
  </tw-modal>
</template>

<script lang="ts">
import { closeModal, sendResetLinkEmail, useModal, useErrors, openModal } from '@/store/security';
import { defineComponent } from 'vue';
import { ResetPasswordForm } from '@/store/models/security';

export interface ForgotData {
  form: ResetPasswordForm;
  promise: Promise<any> | null;
  message: string | null;
}

export default defineComponent({
  name: 'ForgotModal',
  setup() {
    const modal = useModal();
    const errors = useErrors();
    return { closeModal, modal, errors, openModal };
  },
  data: () => ({
    form: {
      email: null,
    },
    promise: null,
    message: null,
  } as ForgotData),
  methods: {
    async submit() {
      this.promise = sendResetLinkEmail(this.form);
      const response = await this.promise;
      if(response.status === 200) {
        this.message = response.data.message;
      }
      this.promise = null;
    },
  },
});
</script>

<style></style>
