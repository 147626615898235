
import { logout, useUser, openModal } from '@/store/security';
import { itemsInCart } from '@/store/shoppingCart';
import { defineComponent } from '@vue/runtime-core';
import Forgot from './Header/Modals/Forgot.vue';
import Login from './Header/Modals/Login.vue';
import Register from './Header/Modals/Register.vue';
import SearchAdvanced from './Header/Search/Advanced.vue';
import SearchFast from './Header/Search/Fast.vue';

export default defineComponent({
  name: 'Header',
  components: {
    Forgot,
    Login,
    Register,
    SearchAdvanced,
    SearchFast,
  },
  props: {
    msg: String,
  },
  setup() {
    const user = useUser();
    return { itemsInCart, logout, user, openModal };
  },
  computed: {
    header(): any {
      return this.$route?.meta?.header;
    },
  },
});
