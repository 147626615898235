import { h, SetupContext } from 'vue';
import { BasicProps } from '@/types';

export default function TwBox(props: BasicProps, context: SetupContext) {
  return h(
    props?.is || 'div',
    {class: 'relative', style: {padding: `0 0 ${100/(Number(props?.ratio) || 1)}%`}},
    h('div', {class: 'absolute w-full h-full'}, context?.slots),
  );
}
