
import { clearErrors, closeModal, register, useErrors, useModal, openModal } from '@/store/security';
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useField, useForm } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: 'RegisterModal',
  setup() {
    onBeforeUnmount(() => clearErrors());
    const modal = useModal();
    const router = useRouter();
    const route = useRoute();
    const errors = useErrors();

    const { handleSubmit, resetForm } = useForm({
      validationSchema: {
        email: 'email|required',
        name: 'min:3|max:60|alpha_spaces|required',
        password: 'required|min:8|max:64',
        password_confirmation: 'required|confirmed:@password',
      },
      initialValues: {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
      },
    });
    resetForm();
    const promise = ref<Promise<any>|null>(null);
    const submit = handleSubmit(values => {
      clearErrors();
      promise.value = register(values).then(user => {
        if (user) {
          resetForm();
          closeModal();
          router.push(route.fullPath);
        }
      }).finally(() => {
        promise.value = null;
      });
    });

    const { value: email, errorMessage: emailError }  = useField('email', null, {label: 'Email'});
    const { value: name, errorMessage: nameError }  = useField('name', null, {label: 'Nombre'});
    const { value: password, errorMessage: passwordError }  = useField('password', null, {label: 'Contraseña'});
    const { value: confirmation, errorMessage: confirmationError }  = useField('password_confirmation', null, {label: 'Confirmar Contraseña'});

    return {
      promise,
      email,
      emailError,
      name,
      nameError,
      password,
      passwordError,
      confirmation,
      confirmationError,
      closeModal,
      modal,
      openModal,
      submit,
      errors,
      resetForm,
    };
  },
  methods: {
    handleCloseModal() {
      this.resetForm();
      this.closeModal();
    },
  },
});
