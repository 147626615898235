
import { defineComponent } from '@vue/runtime-core';
import { FastData } from './types';

export default defineComponent({
  name: 'SearchFast',
  props: {
    autofocus: Boolean,
  },
  data() {
    const query = this.$route.params?.query;
    return {
      input: typeof query === 'string' ? query : '',
      timer: null,
    } as FastData;
  },
  watch: {
    input() {
      this.search();
    },
  },
  methods: {
    search() {
      const query = this.input;
      if ((query?.length || 0) < 3) return;
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.timer = null;
        this.$router.push({ name: 'search', params: { query } });
      }, 300);
    },
  },
});
