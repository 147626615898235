import { defineComponent, h, Slots } from 'vue';
import Button from './Tw/Button';
import Icon from './Icon';
import { BasicProps } from '@/types';

export default defineComponent({
  mixins: [Button],
  props: {
    disabled: Boolean,
    disableOnPromise: Boolean,
    promise: Promise,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    addToClass() {
      return ['flex', 'padding', 'rounded', 'text'];
    },
    flex() {
      if (/\bflex\b/.test(this.attrClass)) return null;
      return 'flex space-x-2 items-center justify-center';
    },
  },
  watch: {
    promise(val, old) {
      if (val !== old) this.waiting(val);
    },
  },
  created() {
    this.waiting(this.promise);
  },
  methods: {
    async waiting(promise?: Promise<any>) {
      if (!(promise instanceof Promise)) return;
      this.loading = true;
      try {
        await promise;
      } catch(e) {
        // empty
      }
      this.loading = false;
    },
    vnode(el: string, attrs: BasicProps, slots: Slots) {
      const disabled = this.disabled || (this.disableOnPromise && this.loading);
      const vnode = [];
      if(this.loading) vnode.push(h(Icon, {type: 'spinner', class: 'h-4'}));
      vnode.push(h('span', this.loading ? slots?.fallback?.() : slots?.default?.()));
      return h(el, {...attrs, disabled}, vnode);
    },
  },
});
