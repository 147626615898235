<template>
  <tw-modal :model-value="modal === 'register'" bg-close @update:modelValue="handleCloseModal()">
    <div class="cursor-pointer" @click="handleCloseModal()">
      <tw-container class="relative" maxw="max-w-4xl">
        <div class="relative cursor-auto z-10 border-0 h-full bg-white rounded-lg overflow-hidden flex" @click.stop="() => null">
          <bg-image
            class="hidden md:block w-3/5 bg-cover bg-center"
            :src="require('@/assets/CREARCUENTA-IMG.jpg')"
          />
          <div class="flex-grow h-full flex flex-col items-center justify-center p-14">
            <button
              type="button"
              class="absolute top-0.5 right-0.5 h-6 w-6 flex items-center justify-center overflow-hidden"
              data-dismiss="modal"
              aria-label="Close"
              :tabindex="1"
              @click="handleCloseModal()"
            >
              <span class="transform -translate-y-0.5" aria-hidden="true">×</span>
            </button>
            <span class="uppercase font-secondary font-bold text-secondary text-3xl pt-6">Crear Cuenta</span>
            <div class="font-medium space-x-3 py-1">
              <span class="text-secondary text-xs tracking-tighter font-bold">¿Ya eres usuario?</span>
              <button class="text-primary underline px-1 text-xs tracking-tighter font-bold" :tabindex="1" @click.stop="openModal('login')">
                Inicia Sesión
              </button>
            </div>
            <div class="w-full max-w-md mx-auto py-1">
              <form class="py-6" @submit.prevent="submit()">
                <label class="block py-2 space-y-2">
                  <span class="block text-xs ml-3 tracking-tighter text-secondary">
                    Nombre:
                  </span>
                  <input
                    id="name"
                    v-model="name"
                    class="shadow appearance-none border rounded w-full py-2 px-3 placeholder-gray-700 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Escribe nombre completo"
                    required
                    autocomplete="name"
                    autofocus
                  />
                  <p v-if="nameError" class="text-red-500 text-sm italic mt-1">
                    {{ nameError }}
                  </p>
                </label>
                <label class="block py-2 space-y-2">
                  <span class="block text-xs ml-3 tracking-tighter text-secondary">
                    Correo electrónico:
                  </span>
                  <input
                    id="email"
                    v-model="email"
                    name="email"
                    class="shadow appearance-none border rounded w-full py-2 px-3 placeholder-gray-700 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline"
                    type="email"
                    placeholder="Ingresa correo"
                    required
                    autocomplete="email"
                  />
                  <p v-if="emailError" class="text-red-500 text-sm italic mt-1">
                    {{ emailError }}
                  </p>
                  <p v-if="errors?.errors?.email" class="text-red-500 text-sm italic mt-1">
                    {{ errors.errors.email[0] }}
                  </p>
                </label>
                <label class="block py-2 space-y-2">
                  <span class="block text-xs ml-3 tracking-tighter text-secondary">
                    Contraseña:
                  </span>
                  <input
                    id="password"
                    v-model="password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 placeholder-gray-700 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline"
                    type="password"
                    placeholder="Escribe contraseña"
                    required
                    autocomplete="new-password"
                  />
                  <p v-if="passwordError" class="text-red-500 text-sm italic mt-1">
                    {{ passwordError }}
                  </p>
                </label>
                <label class="block py-2 space-y-2">
                  <span class="block text-xs ml-3 tracking-tighter text-secondary">
                    Confirmar Contraseña:
                  </span>
                  <input
                    id="password_confirmation"
                    v-model="confirmation"
                    class="shadow appearance-none border rounded w-full py-2 px-3 placeholder-gray-700 text-xs tracking-tighter leading-tight focus:outline-none focus:shadow-outline"
                    type="password"
                    placeholder="Confirma contraseña"
                    required
                    autocomplete="new-password"
                  />
                  <p v-if="confirmationError" class="text-red-500 text-sm italic mt-1">
                    {{ confirmationError }}
                  </p>
                </label>
                <div class="py-6">
                  <promise-button type="submit" class="inline-block uppercase ml-auto font-bold tracking-wide font-secondary bg-secondary text-white hover:bg-primary" :promise="promise" disable-on-promise>
                    <template #default>
                      Crear Cuenta
                    </template>
                    <template #fallback>
                      Creando...
                    </template>
                  </promise-button>
                </div>
                <button @click="resetForm()">Reset</button>
                <div class="text-center">
                  <p class="text-gray-700 text-xs tracking-tighter">
                    Al iniciar sesión, aceptas los
                    <span class="text-secondary text-xs tracking-tighter font-bold">Términos y Condiciones</span>
                    , así como el
                    <span class="text-secondary text-xs tracking-tighter font-bold">Aviso de Privacidad</span>
                    de Rentalo
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </tw-container>
    </div>
  </tw-modal>
</template>

<script lang="ts">
import { clearErrors, closeModal, register, useErrors, useModal, openModal } from '@/store/security';
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useField, useForm } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: 'RegisterModal',
  setup() {
    onBeforeUnmount(() => clearErrors());
    const modal = useModal();
    const router = useRouter();
    const route = useRoute();
    const errors = useErrors();

    const { handleSubmit, resetForm } = useForm({
      validationSchema: {
        email: 'email|required',
        name: 'min:3|max:60|alpha_spaces|required',
        password: 'required|min:8|max:64',
        password_confirmation: 'required|confirmed:@password',
      },
      initialValues: {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
      },
    });
    resetForm();
    const promise = ref<Promise<any>|null>(null);
    const submit = handleSubmit(values => {
      clearErrors();
      promise.value = register(values).then(user => {
        if (user) {
          resetForm();
          closeModal();
          router.push(route.fullPath);
        }
      }).finally(() => {
        promise.value = null;
      });
    });

    const { value: email, errorMessage: emailError }  = useField('email', null, {label: 'Email'});
    const { value: name, errorMessage: nameError }  = useField('name', null, {label: 'Nombre'});
    const { value: password, errorMessage: passwordError }  = useField('password', null, {label: 'Contraseña'});
    const { value: confirmation, errorMessage: confirmationError }  = useField('password_confirmation', null, {label: 'Confirmar Contraseña'});

    return {
      promise,
      email,
      emailError,
      name,
      nameError,
      password,
      passwordError,
      confirmation,
      confirmationError,
      closeModal,
      modal,
      openModal,
      submit,
      errors,
      resetForm,
    };
  },
  methods: {
    handleCloseModal() {
      this.resetForm();
      this.closeModal();
    },
  },
});
</script>

<style></style>
