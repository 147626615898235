import { BasicProps } from '@/types';
import { defineComponent, h, Slots } from 'vue';
import Button from './Button';

export default defineComponent({
  name: 'TwButtonIcon',
  mixins: [Button],
  inheritAttrs: false,
  props: {
    translate: String,
  },
  computed: {
    addToClass() {
      return ['padding', 'position', 'rounded'];
    },
    hasPosition(): boolean {
      return /(?:^|\s)(?:absolute|hidden|relative)(?:\s|$)/.test(this?.attrClass);
    },
    padding(): string | null {
      if (this.hasPadding) return null;
      return (
        (this.lg && 'p-7') ||
        (this.md && 'p-6') ||
        (this.sm && 'p-5') ||
        'p-5'
      );
    },
    position(): string | null {
      return this.hasPosition ? null : 'relative';
    },
    rounded(): string | null {
      return /\brounded/.test(this.attrClass) ? null : 'rounded-full';
    },
  },
  methods: {
    vnode: (el: string, attrs: BasicProps, slots: Slots) => h(el, attrs, h(
      'div',
      {class: 'absolute inset-0 flex justify-center items-center'},
      slots?.default?.(),
    )),
  },
});
