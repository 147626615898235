import { BasicProps } from '@/types';
import { camelize } from '@vue/shared';
import icons from './icons';

const xmlns = 'http://www.w3.org/2000/svg';

const icon = (type: string, props: BasicProps) => icons[type] ? icons[type](props) : null;

export default function Icon({type, ...props}: IconProps) {
  // avoid repeating classes
  if ('class' in props) delete props.class;

  return icon(camelize(type), { xmlns, ...props });
}

interface IconProps extends BasicProps {
  type: string;
}
