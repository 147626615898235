
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
});
