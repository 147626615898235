<template>
  <bg-image
    is="header"
    class="bg-cover bg-clip-border bg-gradient-to-b from-primary to-secondary"
    over="linear-gradient(#ffffff33, #ffffff33)"
    under="linear-gradient(to bottom, var(--color-primary), var(--color-secondary))"
    :class="header?.home ? 'bg-bottom 3xl:bg-center' : 'bg-top'"
    :src="require('@/assets/header-bg-02.png')"
  >
    <tw-container is="nav" class="py-3 flex items-center justify-center md:justify-between font-secondary uppercase">
      <router-link to="/" class="font-bold flex items-center text-white">
        <r-icon type="logo" color class="h-12 mr-3" />
        <span class="font-secondary text-3xl">RENTALO</span>
      </router-link>
      <div class="fixed bottom-0 inset-x-0 z-10 bg-white border-2 p-5 / md:static md:bg-transparent md:border-0 md:p-0 text-xs sm:text-base">
        <div class="space-x-3 md:space-x-5 text-gray-800 md:text-white font-medium text-center flex items-center justify-center">
          <r-icon type="questionMark" class="h-14 md:hidden text-gray-600" />
          <router-link :to="{ name : 'Faqs' }">
            <span class="p-1 hidden md:block">Ayuda</span>
          </router-link>
          <template v-if="user !== null">
            <a href="#" @click="logout()">
              Cerrar sesión
            </a>
            <tw-button :to="{ name: 'profile' }" class="uppercase bg-secondary text-white hover:bg-primary md:hover:bg-white md:hover:text-primary">
              {{ user.name }}
            </tw-button>
          </template>
          <template v-else>
            <tw-button class="uppercase p-1" @click.stop.prevent="openModal('login')">
              Iniciar sesión
            </tw-button>
            <tw-button class="uppercase bg-secondary text-white hover:bg-primary md:hover:bg-white md:hover:text-primary" @click.stop.prevent="openModal('register')">
              Crear Cuenta
            </tw-button>
          </template>
          <router-link id="carrito" :to="{ name: 'Quote' }">
            <tw-button-icon class="group focus:outline-none bg-primary text-white hover:bg-secondary md:bg-white md:hover:bg-white md:text-primary md:hover:text-secondary">
              <r-icon type="cart" class="h-6" />
              <tw-button-icon is="div" v-if="itemsInCart > 0" id="carrito-items" class="bg-secondary group-hover:bg-primary md:group-hover:bg-secondary text-white font-primary text-xxs absolute top-0 right-0 p-2.5 transform translate-x-1 -translate-y-1">
                {{ itemsInCart > 9 ? '9+' : itemsInCart }}
              </tw-button-icon>
            </tw-button-icon>
          </router-link>
        </div>
      </div>
    </tw-container>
    <tw-container
      is="section"
      v-if="header?.search !== false || header?.home"
      class="flex flex-col items-center"
      :class="header?.home?'py-20':'py-5'"
    >
      <h1 v-if="header?.home" class="text-center font-secondary mb-5 leading-tight text-white uppercase">
        <span class="text-4xl lg:text-5xl">La mejor red de</span>
        <br />
        <span class="text-5xl lg:text-6xl font-bold">maquinaria en alquiler</span>
      </h1>
      <template v-if="header?.search !== false">
        <SearchFast v-if="header?.search?.advanced === false" autofocus :class="{'max-w-xl': header?.home}" />
        <SearchAdvanced v-else autofocus :class="{'max-w-xl': header?.home}" />
      </template>
    </tw-container>
    <Forgot />
    <Login />
    <Register />
  </bg-image>
</template>

<script lang="ts">
import { logout, useUser, openModal } from '@/store/security';
import { itemsInCart } from '@/store/shoppingCart';
import { defineComponent } from '@vue/runtime-core';
import Forgot from './Header/Modals/Forgot.vue';
import Login from './Header/Modals/Login.vue';
import Register from './Header/Modals/Register.vue';
import SearchAdvanced from './Header/Search/Advanced.vue';
import SearchFast from './Header/Search/Fast.vue';

export default defineComponent({
  name: 'Header',
  components: {
    Forgot,
    Login,
    Register,
    SearchAdvanced,
    SearchFast,
  },
  props: {
    msg: String,
  },
  setup() {
    const user = useUser();
    return { itemsInCart, logout, user, openModal };
  },
  computed: {
    header(): any {
      return this.$route?.meta?.header;
    },
  },
});
</script>

<style></style>
