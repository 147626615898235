import { computed, readonly, ref, watch } from 'vue';
import http from '../http-common';
import router from '../router';
import { Credentials, Errors, NewUserForm, UpdateUserForm, UploadFilesForm, SendResetLinkForm, ResetPasswordForm, User } from './models/security';
import { fetchCart, resetCart } from './shoppingCart';
import { session } from './storage';

const user = session<User|null>('user');
const errors = ref<Errors|null>(null);
const modal = ref('');

const hasAuthCookie = () => /(?:^|;\s*)rd-authenticated\s*=\s*true(?:\s*;|$)/.test(document.cookie);
const authenticated = ref<boolean>(hasAuthCookie());
watch(user, () => authenticated.value = hasAuthCookie());

export const useUser = () => readonly(user);
export const refUser = () => user;
export const useErrors = () => readonly(errors);
export const useModal = () => readonly(modal);

export const hasErrors = computed(() => errors.value !== null);

export function clearErrors() {
  errors.value = null;
}

export const isAuthenticated = () => authenticated.value;

export async function login(credentials: Credentials) {
  try {
    errors.value = null;
    await http.get('/sanctum/csrf-cookie');
    await http.post('/login', credentials);
    await me(true);
    return isAuthenticated();
  } catch (error) {
    errors.value = error.response.data;
    throw error;
  }
}

export async function logout() {
  user.value = null;
  authenticated.value = false;
  resetCart();
  await http.post('/logout');
  window.location.reload(false);
}

export async function me(redirect: boolean = false) {
  try {
    const { data } = await http.get('/api/user');
    user.value = data.data;
    fetchCart();
  } catch(e) {
    user.value = null;
    if (redirect) router.push({ name: 'Home' });
  }
  return user.value;
}

export async function profile() {
  const { data } = await http.get('/api/user?profile=true');
  return user.value = data.data;
}

export async function register(form: NewUserForm) {
  try {
    await http.get('/sanctum/csrf-cookie');
    await http.post('/register', form);
    await me(true);
  } catch (error) {
    errors.value = error.response.data;
  }
  return user.value;
}

export async function resetPassword(form: SendResetLinkForm) {
  try {
    const response = await http.post('/password/reset', form);
    return response;
  } catch (error) {
    errors.value = error.response.data;
    return error.response;
  }
}

export async function sendResetLinkEmail(form: ResetPasswordForm) {
  try {
    const response = await http.post('/password/email', form);
    return response;
  } catch (error) {
    errors.value = error.response.data;
    return error.response;
  }
}

export async function update(form: UpdateUserForm) {
  const { data } = await http.put('/api/user?profile=true', form);
  updateUser(data.data);
}

export async function uploadFiles(form: UploadFilesForm) {
  const formData = new FormData();
  for(const name in form) {
    if(form[name] !== null) {
      formData.append(name, form[name]);
    }
  }
  try {
    const { data } = await http.post('/api/user/files?profile=true', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    updateUser(data.data);
  } catch(e) {
    // user.value = null;
  }
}

function updateUser(value: User) {
  user.value = { ...user.value, ...value };
}

export function closeModal() {
  modal.value = '';
}

export function openModal(value: string) {
  modal.value = value;
}
