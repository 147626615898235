<template>
  <router-link :to="{name: 'category', params: {category: category.slug}}">
    <tw-box :ratio="4/3" class="rounded shadow-md overflox-hidden">
      <bg-image
        class="w-full h-full border rounded bg-center bg-no-repeat bg-cover"
        :src="category.image"
        :empty="require('@/assets/full-logo.svg')"
        over="linear-gradient(transparent, transparent, var(--color-primary))"
      >
        <div class="text-md text-center text-white font-semibold uppercase px-9 py-3 tracking-wide absolute bottom-0 w-full">
          {{ category.name }}
        </div>
      </bg-image>
    </tw-box>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style></style>
