
import VueHeader from '@/components/Header.vue';
import VueFooter from '@/components/Footer.vue';

export default {
  name: 'ContainerBase',
  components: {
    VueHeader,
    VueFooter,
  },
};
