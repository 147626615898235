import { createRouter, createWebHistory, NavigationGuardNext } from 'vue-router';
import Home from '@/views/Home.vue';
import NotFound from '@/views/NotFound.vue';
import ContainerBase from '@/views/ContainerBase.vue';
import { clearErrors, isAuthenticated, openModal } from '@/store/security';
import { getEquipment } from '@/store/equipment';

const routes = [
  {
    path: '/',
    component: ContainerBase,
    children: [
      {
        path: '/',
        meta: { header: { home: true }, footer: { contact: true} },
        name: 'Home',
        component: Home,
      },
      {
        path: '/category',
        name: 'categories',
        component: () => import(/* webpackChunkName: "category" */ '@/views/Categories.vue'),
      },
      {
        path: '/category/:category',
        name: 'category',
        props: true,
        component: () => import(/* webpackChunkName: "category" */ '@/views/Category.vue'),
      },
      {
        path: '/equipment/:slug',
        name: 'equipment',
        props: true,
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/Equipment.vue'),
        beforeEnter: (to: any, from: any, next: NavigationGuardNext) => {
          getEquipment(to.params.slug);
          next();
        },
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
      },
      {
        path: '/quote',
        name: 'Quote',
        component: () => import(/* webpackChunkName: "quote" */ '@/views/Quote.vue'),
      },
      {
        path: '/faqs',
        name: 'Faqs',
        component: () => import(/* webpackChunkName: "quote" */ '@/views/Faqs.vue'),
      },
      {
        path: '/password/reset/:token',
        name: 'password_reset',
        props: true,
        component: () => import(/* webpackChunkName: "password_reset" */ '@/views/ResetPassword.vue'),
      },
      {
        path: '/search/:query',
        meta: { header: { search: { advanced: false } } },
        name: 'search',
        props: true,
        component: () => import(/* webpackChunkName: "search" */ '@/views/SearchView.vue'),
      },
      {
        path: '/order_confirmation',
        name: 'order_confirmation',
        component: () => import(/* webpackChunkName: "order_confirmation" */ '@/views/OrderConfirmation.vue'),
        beforeEnter: (to: any, from: any, next: NavigationGuardNext) => {
          if (!isAuthenticated()) {
            openModal('register');
          } else next();
        },
      },
      {
        path: '/operation_success',
        alias: '/order_success',
        name: 'operation_success',
        component: () => import(/* webpackChunkName: "operation_success" */ '@/views/OrderSuccess.vue'),
      },
    ],
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    beforeEnter(to: any, from: any, next: NavigationGuardNext) {
      if (!isAuthenticated()) {
        openModal('login');
      } else next();
    },
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/GeneralData.vue'),
      },
      {
        path: 'references',
        name: 'references',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/References.vue'),
      },
      {
        path: 'documentation',
        name: 'documentation',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Documentation.vue'),
      },
      {
        path: 'edit_profile',
        name: 'edit_profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/EditProfile.vue'),
      },
      {
        path: 'operation/:uuid',
        name: 'operation',
        props: true,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Operation.vue'),
      },
      {
        path: 'operations',
        name: 'operations',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Operations.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ top: 0, behavior: 'smooth' }),
});

router.beforeEach((to, from, next) => {
  if (['login', 'signup'].includes(to.name as any)) {
    clearErrors();
    if (isAuthenticated()) return next({ name: 'Home' });
  }
  next();
});

export default router;
