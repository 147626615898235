export function normalizeClass(value: any) {
  let res = '';
  if (isString(value)) {
    res = value;
  }
  else if (isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      res += normalizeClass(value[i]) + ' ';
    }
  }
  else if (isObject(value)) {
    for (const name in value) {
      if (value[name]) {
        res += name + ' ';
      }
    }
  }
  return res.trim();
}

const isString = (val: any) => typeof val === 'string';
const isArray = Array.isArray;
const isObject = (val: any) => val !== null && typeof val === 'object';
