import { BasicProps } from '@/types';
import { defineComponent, h, resolveComponent, Slots } from 'vue';
import { normalizeClass } from './utils';

export default defineComponent({
  name: 'TwButton',
  inheritAttrs: false,
  props: {
    is: String,
    to: [String, Object],
    disabled: Boolean,
    sm: Boolean,
    md: Boolean,
    lg: Boolean,
  },
  computed: {
    addToClass: () => ['padding', 'rounded', 'text'],
    attrClass(): string {
      return normalizeClass(this.$attrs?.class || '');
    },
    classes(): string {
      const classes = [];
      classes.push(this.disabled ? 'bg-opacity-75 cursor-not-allowed' : 'cursor-pointer');
      this.addToClass.forEach(value => {
        classes.push((this as any)?.[value] as string);
      });
      classes.push(this.attrClass);

      return normalizeClass(classes);
    },
    el(): string {
      return this.is || (this.to ? 'div' : (this.$attrs.href ? 'a' : 'button'));
    },
    hasPadding() {
      return /p[xy]?-/.test(this.attrClass as any);
    },
    padding(): string | null {
      if (this.hasPadding) return null;
      return (
        (this.lg && 'px-6 py-4') ||
        (this.md && 'px-5 py-3') ||
        (this.sm && 'px-4 py-2') ||
        'px-4 py-2'
      );
    },
    rounded(): string | null {
      if (/\brounded/.test(this.attrClass)) return null;
      const rounded = this.$attrs.rounded;
      return (rounded === false ? '' : rounded === true || rounded === '' ? 'rounded' :
        (!rounded ? 'rounded-full' : `rounded-${rounded}`)
      );
    },
    text: () => 'font-semibold text-center',
    type(): string | null {
      return (this.$attrs?.type as string) || (this.el === 'button' ? 'button' : null);
    },
  },
  methods: {
    vnode: (el: string, attrs: BasicProps, slots: Slots) => h(el, attrs, slots?.default?.()),
  },
  render() {
    const vnode = this.vnode(
      this.el,
      {...this.$attrs, class: this.classes, disabled: this.disabled ? 'disabled' : null, type: this.type},
      this.$slots,
    );
    if (!this.to || this.disabled) return vnode;
    const routeLink = resolveComponent('router-link');
    return h(routeLink, { to: this.to }, () => vnode);
  },
});
