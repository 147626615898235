import { createApp } from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
// import { createI18n } from './i18n';
// import { useTheme } from './theme';
import globalComponents from './components/globals';

import './assets/main.scss';
import './assets/tailwind.css';
//import './assets/styles/hero_pattern.css';
import '@splidejs/splide/dist/css/splide.min.css';

import './common/validationRules';

// support for dark mode
// document.body.className = useTheme().body;

// support for internationalization
// const i18n = createI18n();

createApp(App)
  // .use(i18n)
  .use(router)
  .use(globalComponents)
  .mount('#app');
