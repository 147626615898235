/* register global components */
import { Plugin } from 'vue';
// Components
import BgImage from './BgImage.vue';
import RIcon from './Icon';
import PromiseButton from './PromiseButton';
import TwButton from './Tw/Button';
import TwButtonIcon from './Tw/ButtonIcon';
import TwBox from './Tw/Box';
import TwContainer from './Tw/Container';
import TwModal from './Tw/Modal/index.vue';

export default {
  install(app) {
    const components = Object.entries({
      BgImage,
      RIcon,
      PromiseButton,
      TwButton,
      TwButtonIcon,
      TwBox,
      TwContainer,
      TwModal,
    });

    components.map(([name, component]: [string, any]) => {
      app.component(name, component);
    });
  },
} as Plugin;
