import { DirectiveBinding } from 'vue';
import { Directive, MixinComponent } from '../types';
import { addEventListener, removeEventListeners } from './events';

function addHandler(el: HTMLElement, binding: DirectiveBinding<Function|undefined>) {
  removeHandler(el);

  const callback = binding.value;
  const vm = binding.instance;

  if (callback === null) return;
  if (!(callback instanceof Function)) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`moveout expects a function value, got ${callback}`);
    }
    return;
  }

  let initialMacrotaskEnded = false;
  setTimeout(function() {
    initialMacrotaskEnded = true;
  }, 0);

  const handler = {
    click(e: Event) {
      const target = <Node>e.target;
      if (initialMacrotaskEnded && !el.contains(target)) {
        callback.call(vm, e);
      }
    },
    focusout(e: Event) {
      setTimeout(() => {
        const focus = document.activeElement;
        if (!el.contains(focus)) {
          callback.call(vm, e);
        }
      }, 0);
    },
  };

  const id = rid();
  el.dataset.id = id;
  addEventListener(document.body, `click.${id}`, handler.click);
  addEventListener(el, 'focusout', handler.focusout);
}

function removeHandler(el: HTMLElement) {
  const id = el.dataset.id;
  removeEventListeners(document.body, `click.${id}`);
  removeEventListeners(el, 'focusout');
}

/** Random id (6 characters string) */
const rid = () => Math.random().toString(36).substr(2, 6);

export const Moveout: Directive = {
  mounted: addHandler,
  updated(el, binding) {
    if (binding.value !== binding.oldValue) addHandler(el, binding);
  },
  beforeUnmount: removeHandler,
};

export const directive = Moveout;

export const mixin: MixinComponent = {
  directives: { Moveout },
};
