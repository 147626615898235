import { computed, reactive, toRef, watch } from 'vue';
import { dateToISO, toDate } from '@/filters';
import { Data, Jsonable, JsonableObject, JsonKey, Reactive, ToRef } from '@/types';

const STORAGE = `store-${process.env.VUE_APP_SLUG}`;

const handler: Data<Storage> = {
  local: localStorage,
  session: sessionStorage,
};

export const data = Object.keys(handler).reduce((data, type: string) => {
  const storage = reactive<JsonableObject>(JSON.parse(handler[type].getItem(STORAGE) || '{}'));
  watch(storage, value => {
    handler[type].setItem(STORAGE, JSON.stringify(value));
  });
  data[type] = storage;
  return data;
}, {} as Data<Reactive<JsonableObject>>);

export const refHandler = (store: string) => <T extends Jsonable>(key: JsonKey, value: T | null = null): ToRef<T> => {
  const stored = data[store][key];
  const r = stored !== undefined ? stored : value;
  data[store][key] = r;
  return toRef(data[store], key);
};

export const refDateHandler = (store: string) => (key: JsonKey, value: Date | null = null) => {
  const date = refHandler(store)<string|null>(key);
  const comp = computed({
    get: () => date.value === null ? null : toDate(date.value) || new Date,
    set(value) {
      date.value = dateToISO(value) || null;
    },
  });
  Reflect.set(window, <any>key, comp);
  if (comp.value === null) comp.value = value;
  return comp;
};
