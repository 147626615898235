import { readonly, ref } from 'vue';
import api from '@/common/api';
import http from '@/http-common';
import router from '@/router';
import { Category } from './models/categories';

const categories = ref<Category[]>([]);
const category = ref<Category|null>(null);

export const refCategories = () => categories;
export const useCategories = () => readonly(categories);
export const refCategory = () => category;
export const useCategory = () => readonly(category);

export function setCategory(data: Category) {
  category.value = data;
}

export function setCategories(data: Category[]) {
  categories.value = data;
}

export const getCategories = (): Promise<Category[]> => http.get(api.category.index).then(res => {

  res.data.data.sort(function(a: any, b: any) {
    const order = [
      'montacargas-y-manejo-de-materiales',
      'plataformas-de-elevacion',
      'plataformas-de-tijera',
      'equipo-mueve-tierra',
      'generacion-e-iluminacion',
      'equipo-ligero-y-herramientas',
    ];
    const indexA = order.indexOf(a.slug);
    const indexB = order.indexOf(b.slug);
    return indexA - indexB;
  });

  categories.value = res.data.data;
  return categories.value;
}).catch(error => {
  console.error(error);
  return [];
});

export const getCategory = (name: string) => http.get(api.category.show(name)).then(res => {
  category.value = res.data.data;
  return category.value;
}).catch(error => {
  console.error(error);
  router.replace({ name: 'not-found' });
  return null;
});
