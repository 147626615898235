export const fly = {
  'enter-active-class': 'transition ease-out duration-200',
  'enter-from-class': 'opacity-0 translate-y-1',
  'enter-to-class': 'opacity-100 translate-y-0',
  'leave-active-class': 'transition ease-in duration-150',
  'leave-from-class': 'opacity-100 translate-y-0',
  'leave-to-class': 'opacity-0 translate-y-1',
};

export const mobile = {
  'enter-active-class': 'transition ease-out duration-200',
  'enter-from-class': 'opacity-0 scale-95',
  'enter-to-class': 'opacity-100 scale-100',
  'leave-active-class': 'transition ease-in duration-100',
  'leave-from-class': 'opacity-100 scale-100',
  'leave-to-class': 'opacity-0 scale-95',
};

export const opacity = {
  'enter-active-class': 'ease-out duration-300',
  'enter-from-class': 'opacity-0',
  'enter-to-class': 'opacity-100',
  'leave-active-class': 'ease-in duration-200',
  'leave-from-class': 'opacity-100',
  'leave-to-class': 'opacity-0',
};

export const scale = {
  'enter-active-class': 'ease-out duration-300',
  'enter-from-class': 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
  'enter-to-class': 'opacity-100 translate-y-0 sm:scale-100',
  'leave-active-class': 'ease-in duration-200',
  'leave-from-class': 'opacity-100 translate-y-0 sm:scale-100',
  'leave-to-class': 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
};
