<template>
  <section class="font-primary bg-orange-500 bg-opacity-90 py-10 md:py-20">
    <form autocomplete="off" @submit.stop.prevent="submit">
      <tw-container class="text-white grid sm:grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-3">
        <div class="sm:col-span-2 md:col-span-1 md:row-span-4 flex flex-col py-4">
          <span class="font-secondary font-bold text-4xl uppercase py-3">
            Contáctanos
          </span>
          <span class="mt-2 uppercase">
            Envíanos un mensaje con tus dudas o comentarios
            y te contactaremos a la brevedad.
          </span>
        </div>
        <label class="control">
          <span>Nombre:</span>
          <input v-model="input.name" class="text-sm" name="name" type="text" placeholder="Escribe nombre completo" required />
        </label>
        <label class="control">
          <span>Teléfono:</span>
          <input v-model="input.phone" class="text-sm" name="phone" type="tel" placeholder="Ingresa número" required />
        </label>
        <label class="control">
          <span>Correo Electrónico:</span>
          <input v-model="input.email" class="text-sm" name="email" type="email" placeholder="Ingresa correo" required />
        </label>
        <label class="control">
          <span>Asunto:</span>
          <input v-model="input.subject" class="text-sm" name="subject" type="text" placeholder="Escribe asunto" required />
        </label>
        <label class="sm:col-span-2 control">
          <span>Deja tu mensaje:</span>
          <textarea v-model="input.message" class="text-sm" name="message" :rows="7" placeholder="Escribe tu mensaje" required />
        </label>
        <div class="sm:col-span-2 text-right pt-5">
          <tw-button type="submit" class="bg-secondary text-white hover:bg-white hover:text-secondary font-secondary uppercase px-10 py-2">
            Enviar
          </tw-button>
        </div>
      </tw-container>
    </form>
  </section>
</template>

<script>
export default {
  data: () => ({
    input: {
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: '',
    },
  }),
  methods: {
    submit() {
      console.log('submit');
    },
  },
};
</script>

<style>
</style>
