const D2 = '2-digit';

export function currency(value: any, locales: Locales = 'mx-ES', currency: string = 'MXN') {
  return parseFloat(value)?.toLocaleString?.(locales, {style: 'currency', currency});
}

export function date(value: Dateable, locale: Locales = 'mx-ES') {
  return toDate(value)?.toLocaleDateString?.(locale, {day: D2, month: D2, year: 'numeric', timeZone: 'UTC'}) || null;
}

export function formattedDate(value: Dateable, locale: Locales = 'mx-ES') {
  // return date(value, locale)?.replaceAll('/', ' / ');
  const responseDate: string|null = date(value, locale);
  return responseDate ? responseDate.split('/').join(' / ') : '';
}

export function datetime(value: Dateable, locales: Locales = 'mx-ES') {
  return toDate(value)?.toLocaleString?.(locales, {day: D2, month: D2, year: 'numeric', hour: D2, minute: D2, hour12: true})
    ?.replace?.(/\s([ap])\.\sm\.$/i, (...a) => a[1].toUpperCase()+'M');
}

const dateISO = (date: Dateable) => toDate(date)?.toISOString?.() || null;
export const dateToISO = (date: Dateable, full: boolean = false) => (full ? dateISO(date) : dateISO(date)?.substr?.(0, 10)) || null;

export function toDate(value?: Dateable) {
  if (!value) return null;
  const date = value instanceof Date ? value : new Date(value);
  return isNaN(date.getTime()) ? null : date;
}

export function unixToDateTime(value?: number) {
  if (!value) return null;
  return datetime(new Date(value * 1000));
}

type Dateable = string | number | Date | null;
type Locales = string | string[];
