import { BasicProps } from '@/types';

export function deepMerge (a: any, ...more: any[]) {
  if (!more.length) return a;
  return more.reduce((a, b) => {
    const ia = instance(a);
    const ib = instance(b);
    if (ia !== ib) return b;

    if (a instanceof Array) return a.concat(b);

    if (a instanceof Map) {
      for (const [key, bval] of b) {
        a.set(key, a.has(key) ? deepMerge([a.get(key), bval]) : bval);
      }
    }

    if (ia === Object) {
      for (const key in b) {
        const bval = Reflect.get(b, key);
        Reflect.set(a, key, key in a ? deepMerge([Reflect.get(a, key), bval]) : bval);
      }
      return a;
    }

    return b;
  }, a);
}

export function instance(item: any) {
  if (item instanceof Array) return Array;
  if (item instanceof Map) return Map;
  if (item instanceof Promise) return Promise;
  if (item instanceof Function) return Function;
  if (item === null) return null;
  if (item === undefined) return undefined;
  switch (typeof item) {
  case 'boolean': return Boolean;
  case 'string': return String;
  case 'number': return Number;
  case 'symbol': return Symbol;
  case 'object': return Object;
  default: return undefined;
  }
}

export function emptyObject(obj: Object) {
  for (const key in obj) return false;
  return true;
}

const COLOR_REGEXS = [
  /^#(?:(?:[0-9a-f]{3}){1,2}|[0-9a-f]{8})$/i, //exa
  /^var\([^)]+\)$/i, //var
  /^rgb\([^)]+\)$/i, //rgb
];

const VALID_IMAGES_REGEX = /^(?:url|linear-gradient)\(.+\)$/i;

function format(values: string | string[]) {
  if (!(values instanceof Array)) values = [values];
  return values.map(value => COLOR_REGEXS.some(regex => regex.test(value)) ? `linear-gradient(${value}, ${value})` : value);
}

export function getBackgroundImages(props: BasicProps): string {
  let image = props.src || '';
  if (!/^(?:\/|https?:)/.test(image)) image = process.env?.VUE_APP_BACKEND + image;

  let images = [];
  if (image) images.push(`url(${image})`);
  if (props.empty && !image) images.push(`url(${props.empty})`);
  if (props.under) images = [...images, ...format(props.under)];
  if (props.over) images = [...format(props.over), ...images];

  return images.filter(img => VALID_IMAGES_REGEX.test(img)).join(', ');
}

export function getPages({glue = 0, max = 7, first = 1, pages = 1, current = 1}): number[] {
  const secuence = (num: number, start = 0): number[] => Array(num).fill(first+start).map((f, n) => f+n);
  const n = current - first;
  const last = pages - first;

  if (max < 5) max = 5; // max: 5 pages or more

  if (pages <= max) return secuence(pages); //show all

  // hodd (half / odd pages)
  const hodd = max % 2 === 1;

  // hl (half-low), hh (half-high)
  const hl = Math.floor(max/2);
  const hh = hl + ~~hodd;

  if (n < hl) {
    return [...secuence(hh), glue, ...secuence(hl, last-hl+1)];
  }

  if (n === hl) {
    return [...secuence(hl+2), glue, ...secuence(max-hl-2, last-hl+2)];
  }

  if (n > hl && n < last - hl) {
    // qodd (quarter / odd half)
    const qodd = hl % 2 === 1;
    // ql (quarter-low), qh (quarter-high)
    const ql = Math.floor(hl/2);
    const qh = ql + ~~qodd;
    return [...secuence(qh), glue, ...secuence(ql*2 + ~~hodd, n-ql), glue, ...secuence(qh, last-ql)];
  }

  if (n === last - hl) {
    return [...secuence(max-hl-2), glue, ...secuence(hl+2, last-hl-1)];
  }

  if (n > last - hl) {
    return [...secuence(hl), glue, ...secuence(hh, last-hh+1)];
  }

  return [];
}
