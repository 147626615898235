import { computed, reactive, readonly, watch, ref } from 'vue';
import http from '../http-common';
import { dateToISO } from '../filters';
import { isAuthenticated } from '../store/security';
import { CartItem, Location, Quote } from './models/shoppingCart';
import { session } from './storage';
import { Data } from '@/types';

const today = new Date;
const twoDaysAfter = (new Date);
const tomorrow = (new Date);
const creating = ref<boolean>(false);
tomorrow.setDate(today.getDate() + 1);
twoDaysAfter.setDate(today.getDate() + 2);

const cart = session<CartItem[]>('cart.cart', []);
const quote = session<Quote|null>('cart.quote');
const location = session<Location|null>('cart.location');
const startDate = session<string|null>('cart.startDate');
const endDate = session<string|null>('cart.endDate');

// validating dates
if (!startDate.value || <string>startDate.value <= <string>dateToISO(today)) startDate.value = dateToISO(tomorrow);
if (!endDate.value) endDate.value = dateToISO(twoDaysAfter);
if (<string>startDate.value > <string>endDate.value) endDate.value = startDate.value;
watch(startDate, date => {
  if (date && (!endDate.value || date > endDate.value)) endDate.value = date;
});
watch(endDate, date => {
  if (date && (!startDate.value || date < startDate.value)) startDate.value = date;
});

export const refStartDate = () => startDate;
export const useCreating = () => readonly(creating);
export const refEndDate = () => endDate;
export const useCart = () => readonly(cart);
export const useLocation = () => readonly(location);
export const useQuote = () => readonly(quote);
export const useStartDate = () => readonly(startDate);
export const useEndDate = () => readonly(endDate);
export const useToday = () => readonly(today);
export const useTomorrow = () => readonly(tomorrow);

const state = reactive({ cart, quote, location, startDate, endDate, creating});

export function addToCart(item: CartItem) {
  if (!state.location) return;
  if (!isInCart(item)) {
    state.cart.push({
      location: state.location,
      start_date: state.startDate,
      end_date: state.endDate,
      ...item,
    });
    uploadCart();
  }
}

export function addToCartCustomDate(item: CartItem, startDate: string, endDate: string) {
  if (!state.location) return;
  if (!isInCart(item)) {
    state.cart.push({
      location: state.location,
      start_date: startDate,
      end_date: endDate,
      ...item,
    });
    uploadCart();
  }
}

export async function emptyCart() {
  await http.delete('/api/carts');
  state.cart = [];
}

export async function fetchCart() {
  try {
    const { data } = await http.get('/api/carts');
    const items: CartItem[] = data?.data?.items || [];
    items.forEach(item => addToCart(item));
  } catch(error) {
    if (error?.response?.status === 404) {
      await http.post('/api/carts', formatCart());
    }
  }
}

const formatCart = ():  CartItem[] => state.cart.map((item: CartItem) => ({
  id: item.id,
  location: state.location,
  start_date: item.start_date,
  end_date: item.end_date,
}));

export function getUserLocation() {
  // TODO: implement Geocoding API
  // https://developers.google.com/maps/documentation/geocoding/start
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  if (!navigator.geolocation) {
    console.log('Unable to retrieve your location');
  } else {
    navigator.geolocation.getCurrentPosition(position => {
      console.log(position);
    }, () => console.log('Unable to retrieve your location'), options);
  }
}

export const itemsInCart = computed(() => state.cart.length);

export async function itemIsAvailable(item: CartItem) {
  const res = await http.get(`api/items/isAvailable/${item.id}/${dateToISO(state.startDate)}/${dateToISO(state.endDate)}`);
  if (res.status === 200) {
    return res.data.response;
  } else {
    return false;
  }
}

export async function itemIsAvailableCustomDate(itemId: Number, startDate: Date, endDate: Date) {
  const res = await http.get(`api/items/isAvailable/${itemId}/${dateToISO(startDate)}/${dateToISO(endDate)}`);
  if (res.status === 200) {
    return res.data.response;
  } else {
    return false;
  }
}

export const isInCart = (item: CartItem) => state.cart.some((current: CartItem) => current.id === item.id);

export function removeFromCart(item: CartItem) {
  if (isInCart(item)) {
    removeItem(item);
    uploadCart();
  }
}

export function removeItem(item: CartItem) {
  const index = state.cart.findIndex((current: CartItem) => current.id === item.id);
  if (index >= 0) state.cart.splice(index, 1);
}

export function resetCart() {
  const today = new Date;
  const twoDaysAfter = (new Date);
  twoDaysAfter.setDate(today.getDate() + 2);

  state.cart = [];
  state.quote = null;
  state.location = null;
  state.startDate = dateToISO(today);
  state.endDate = dateToISO(twoDaysAfter);
}

export function setEndDate(date: string) {
  state.endDate = dateToISO(date);
}

export function setLocation({ value, administrative, country, name, type, latlng }: Location) {
  state.location = { value, administrative, country, name, type, latlng };
}

export function setStartDate(date: string) {
  state.startDate = dateToISO(date);
}

export function setQuote(quote: Quote) {
  state.quote = quote;
}

export function updateItem(item: CartItem) {
  const index = state.cart.findIndex((current: CartItem) => current.id === item.id);
  if (index > 0) state.cart.splice(index, 1, item);
}

async function uploadCart() {
  if (isAuthenticated()) {
    const response = await http.put('/api/carts', formatCart());
    const items: CartItem[] = response?.data?.items;
    items.forEach(item => updateItem(item));
  }
}


export async function createCheckout(content: Data) {
  if (state.creating) return;
  state.creating = true;
  let response = null;
  try {
    response = await http.post('/api/carts/checkout', content);
  } finally {
    state.creating = false;
  }
  return response;
}
