
import { LoginForm } from '@/store/models/security';
import { clearErrors, closeModal, login, useErrors, useModal, openModal } from '@/store/security';
import { defineComponent, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';

interface LoginRetryCountDown {
  msg: string;
  timer: number;
  counter: number;
}

export default defineComponent({
  name: 'LoginModal',
  setup() {
    const countdown = ref<LoginRetryCountDown | null>(null);
    const errors = useErrors();
    const form = ref<LoginForm>({
      email: null,
      password: null,
    });
    const modal = useModal();
    const router = useRouter();
    const route = useRoute();

    const promise = ref<Promise<any>|null>(null);
    const submit = () => {
      if (countdown.value) {
        clearInterval(countdown.value.timer);
        countdown.value = null;
      }
      return promise.value = login(form.value).then(logged => {
        if (logged) {
          closeModal();
          router.push(route.fullPath);
        }
      }).catch(({response}) => {
        if (response?.status === 429) {
          clearErrors();
          const msg = response?.data?.errors?.email?.[0];
          countdown.value = {
            msg,
            timer: setInterval(() => {
              if (!countdown.value) return;
              if (countdown.value.counter > 1) {
                countdown.value.counter--;
                countdown.value.msg = countdown.value.msg.replace(/\d+/, `${countdown.value.counter}`);
              } else {
                clearInterval(countdown.value.timer);
                countdown.value = null;
              }
            }, 1000),
            counter: Number(msg.replace(/\D+/g, '')),
          };
        }
      }).finally(() => {
        promise.value = null;
      });
    };

    return { closeModal, countdown, form, modal, errors, openModal, promise, submit };
  },
});
