<template>
  <div class="w-full relative">
    <div class="relative">
      <input
        id="equipment-query"
        v-model="input"
        class="shadow-lg appearance-none rounded w-full py-4 pl-3 pr-4 text-gray-700 leading-tight focus:shadow-outline"
        type="text"
        placeholder="Buscar equipo"
        autocomplete="off"
        :autofocus="autofocus"
      />
      <div class="absolute right-0 top-0 h-full grid place-content-center mr-3 cursor-pointer" @click="search">
        <r-icon type="search" class="h-4 text-primary" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { FastData } from './types';

export default defineComponent({
  name: 'SearchFast',
  props: {
    autofocus: Boolean,
  },
  data() {
    const query = this.$route.params?.query;
    return {
      input: typeof query === 'string' ? query : '',
      timer: null,
    } as FastData;
  },
  watch: {
    input() {
      this.search();
    },
  },
  methods: {
    search() {
      const query = this.input;
      if ((query?.length || 0) < 3) return;
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.timer = null;
        this.$router.push({ name: 'search', params: { query } });
      }, 300);
    },
  },
});
</script>

<style></style>
