import { readonly } from 'vue';
import { data, refDateHandler, refHandler } from './refs';

export const local = refHandler('local');
export const localDate = refDateHandler('local');

export const session = refHandler('session');
export const sessionDate = refDateHandler('session');

export const refLocal = () => data.local;
export const useLocal = () => readonly(data.local);

export const refSession = () => data.session;
export const useSession = () => readonly(data.session);
