<template>
  <div class="font-body min-h-screen grid grid-rows-layout">
    <router-view />
  </div>
</template>

<script lang="ts">
import { me } from './store/security';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  created() {
    me();
  },
});
</script>
