
import { me } from './store/security';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  created() {
    me();
  },
});
