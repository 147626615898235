import { readonly, ref } from '@vue/reactivity';
import http from '@/http-common';
import api from '@/common/api';
import router from '@/router';
import { Equipment } from './models/equipment';

const equipments = ref<Equipment[]>([]);
const equipment = ref<Equipment|null>(null);

export const refEquipments = () => equipments;
export const useEquipments = () => readonly(equipments);
export const refEquipment = () => equipment;
export const useEquipment = () => readonly(equipment);

export async function getEquipmentsByCategory(name: string) {
  try {
    const { data } = await http.get(api.category.equipment(name));
    equipments.value = data;
    return equipments.value;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getEquipment(slug: string) {
  try {
    equipment.value = null;
    const { data } = await http.get(api.equipment.show(slug));
    equipment.value = data.data;
    return equipment.value;
  } catch (error) {
    console.error(error);
    router.replace({ name: 'not-found' });
    return null;
  }
}

export async function getItemsEquipmentByDates(equipmentData: any) {
  try {
    const { data } = await http.get(api.equipment.itemsByDate(equipmentData));
    setEquipmentItems(data.data);
    return data.data;
  } catch (error) {
    console.error(error);
    router.replace({ name: 'not-found' });
    return null;
  }
}

export function setEquipmentItems(items: any) {
  equipment.value = {
    ...equipment.value,
    items,
  } as any;
}
