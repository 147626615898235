<template>
  <div id="home" class="font-primary md:pt-4">
    <section class="py-10 md:py-12">
      <tw-container class="text-sm md:text-lg text-gray-500 text-center uppercase">
        <span class="hidden md:inline">En RENTALO hemos creado para ti una plataforma en línea ágil y práctica para
          RENTA DE MAQUINARIA.</span> No importa el tamaño de tu proyecto o tarea, estamos seguros
        que encontrarás lo necesario a sólo un click de distancia
      </tw-container>
    </section>
    <tw-container is="section">
      <CategoryList />
    </tw-container>
    <div class="home-bg-01 bg-container bg-cover bg-top -mt-28">
      <section class="bg-secondary bg-opacity-90 pt-28">
        <tw-container class="pt-10 text-white">
          <div class="text-sm md:text-lg text-center uppercase flex flex-col">
            <span>
              Permite que gestionemos para ti, todo en un sólo sitio: RENTAS, TRASLADO, SEGURO
              y obtén las mejores tarifas de las marcas más prestigiosas del mercado
            </span>
            <span class="font-bold mx-auto mt-3">ELEVA TU PROYECTO AL SIGUIENTE NIVEL CON RENTALO</span>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:max-w-68 mx-auto my-8 md:my-14 px-3 text-center">
              <div class="flex md:flex-col justify-center items-center space-x-4 space-y-4">
                <img src="@/assets/home-icon-facil.svg" class="w-20 md:w-40 rounded-3xl p-5 md:p-10 bg-white text-secondary " />
                <h3 class="font-secondary font-bold text-3xl md:text-4xl uppercase">Fácil</h3>
              </div>
              <div class="mt-4">
                <p class="text-sm md:text-base">
                  Encuentra tu equipo en las diferentes categorías y elige la mejor maquinaria para tu proyecto
                </p>
              </div>
            </div>
            <div class="w-full md:max-w-68 mx-auto my-8 md:my-14 px-3 text-center">
              <div class="flex md:flex-col justify-center items-center space-x-4 space-y-4">
                <img src="@/assets/home-icon-rapido.svg" class="w-20 md:w-40 rounded-3xl p-5 md:p-10 bg-white text-secondary " />
                <h3 class="font-secondary font-bold text-3xl md:text-4xl uppercase">Rápido</h3>
              </div>
              <div class="mt-4">
                <p class="text-sm md:text-base">
                  Una vez que haz creado tu cuenta estarás listo para ordenar tu equipo en RENTA de manera ágil
                  y en línea desde tú computadora o dispositivo móvil
                </p>
              </div>
            </div>
            <div class="w-full md:max-w-68 mx-auto my-8 md:my-14 px-3 text-center">
              <div class="flex md:flex-col justify-center items-center space-x-4 space-y-4">
                <img src="@/assets/home-icon-seguro.svg" class="w-20 md:w-40 rounded-3xl p-5 md:p-10 bg-white text-secondary " />
                <h3 class="font-secondary font-bold text-3xl md:text-4xl uppercase">Seguro</h3>
              </div>
              <div class="mt-4">
                <p class="text-sm md:text-base">
                  Recibe tu confirmación vía email y ten la certeza que estamos trabajando para cumplir
                  con tu requerimiento en tiempo y forma
                </p>
              </div>
            </div>
          </div>
        </tw-container>
      </section>
      <section class="bg-white bg-opacity-90 text-gray-600 text-center text-2xl md:text-4xl py-20">
        <tw-container>
          <div class="font-semibold">¡NO BUSQUES MÁS!</div>
          <div class="font-bold text-2xl md:text-5xl font-secondary max-w-3xl mx-auto leading-none text-secondary tracking-normal mb-12">
            TENEMOS LA MAQUINARIA QUE NECESITAS Y EL MEJOR SERVICIO
          </div>
          <div class="flex flex-wrap">
            <div class="w-full max-w-md mx-auto">
              <div class="text-2xl font-semibold">
                Realiza tu reserva
              </div>
              <div class="text-lg md:text-3xl my-5 font-secondary uppercase">
                <tw-button href="#" lg class="bg-primary hover:bg-secondary text-white block" @click="startNow()">
                  Comienza ahora mismo
                </tw-button>
              </div>
            </div>
            <div class="w-full max-w-md mx-auto">
              <div class="text-2xl font-semibold">
                o si lo prefieres llama al
              </div>
              <div class="text-lg md:text-3xl my-5 font-secondary uppercase">
                <tw-button href="tel:8112345678" lg class="bg-primary hover:bg-secondary text-white block">
                  <div class="flex justify-center items-center space-x-3">
                    <r-icon type="phone" class="inline w-7" />
                    <span class="-mt-0.5 mb-0.5">(81) 1234-5678</span>
                  </div>
                </tw-button>
              </div>
            </div>
          </div>
        </tw-container>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core';
import CategoryList from '@/components/Category/List.vue';
/* import AlgoliaPlaces from '@/components/AlgoliaPlaces'; */
import { isAuthenticated, openModal } from '@/store/security';

export default defineComponent({
  name: 'Home',
  components: {
    CategoryList,
    /* AlgoliaPlaces, */
  },
  setup() {
  },
  methods: {
    startNow() {
      if(isAuthenticated()) {
        return;
      }
      openModal('register');
    },
  },
});
</script>

<style lang="scss" scoped>
.home-bg-01 {
  background-image: url('../assets/home-bg-01.png');
}
</style>
