import { BasicProps, Data } from '@/types';

const icons: Data<(props: BasicProps) => JSX.Element> = {
  arrowLeft: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
    </svg>
  ),
  arrowRight: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
    </svg>
  ),
  backspace: props => (
    <svg {...props} viewBox="0 0 48 48">
      <path fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" d="M6 24l11-11a4 4 0 0 1 3-1h18a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H20a4 4 0 0 1-3-1L6 24m18-4l8 8m0-8l-8 8"/>
    </svg>
  ),
  calendar: props => (
    <svg {...props} viewBox="0 0 20 20">
      <path fill="currentColor" d="M1 4c0-1.1.9-2 2-2h2v-2h2v2h6v-2h2v2h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2zm2 2v12h14v-12zM5 9h2v2h-2z m0 4h2v2h-2zm4-4h2v2h-2z m0 4h2v2h-2zm4-4h2v2h-2z m0 4h2v2h-2z"/>
    </svg>
  ),
  cart: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 20 20">
      <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zm13 15.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
    </svg>
  ),
  checkCircle: props => (
    <svg {...props} viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12a9 9 0 11 18 0 9 9 0 11-18 0m6 0l2 2 4-4" />
    </svg>
  ),
  chevronLeft: props => (
    <svg {...props} viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
    </svg>
  ),
  chevronRight: props => (
    <svg {...props} viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
    </svg>
  ),
  edit: props => (
    <svg {...props} viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.5 21h-3.5v-3.5l13.5 -13.5a2.475 2.475 0 1 1 3.5 3.5zm12-12l-3.5 -3.5"/>
    </svg>
  ),
  eye: props => (
    <svg {...props} viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM2.458 12C3.732 7.943 7.523 5 12 5s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7s-8.268-2.943-9.542-7z"/>
    </svg>
  ),
  fileDownload: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 12 12">
      <path d="M8.879 10.547H3.516c-.648-.001-1.174-.526-1.174-1.174V2.299c.001-.648.526-1.174 1.174-1.174h3.518a.35.35 0 0 1 .249.103L9.95 3.897a.35.35 0 0 1 .103.249v5.227c-.001.648-.526 1.174-1.174 1.175zM3.516 1.828a.47.47 0 0 0-.471.471v7.073a.47.47 0 0 0 .471.471h5.363a.47.47 0 0 0 .471-.471V4.291L6.887 1.828z"/>
      <path d="M9.702 4.497H7.856c-.648-.001-1.174-.526-1.174-1.174V1.477a.35.35 0 1 1 .703 0v1.846a.47.47 0 0 0 .471.471h1.846a.35.35 0 1 1 0 .703zM6.197 8.789a.35.35 0 0 1-.249-.103L4.672 7.41a.35.35 0 1 1 .497-.497L6.197 7.94l1.028-1.028a.35.35 0 0 1 .588.158.35.35 0 0 1-.091.34L6.445 8.685a.35.35 0 0 1-.248.104z"/>
      <path d="M6.197 8.555a.35.35 0 0 1-.352-.352V5.414a.35.35 0 1 1 .703 0v2.789a.35.35 0 0 1-.352.352z"/>
    </svg>
  ),
  location: props => (
    <svg {...props} viewBox="0 0 20 20">
      <path fill="currentColor" d="M10 20s-7 -9 -7 -13a7 7 0 0 1 14 0h-5a2 2 0 1 0 -4 0a2 2 0 1 0 4 0h5zs7 -9 7 -13"/>
    </svg>
  ),
  logo: ({color = false, ...props}) => (
    <svg {...props} fill="currentColor" viewBox="0 0 48 48">
      <path class={color!==false&&'text-secondary'} d="M22.838.268c-.015.015-.1.046-.188.069-1.889.481-2.022.579-5.527 4.089l-5.066 5.183c-.033.059.963.068 7.305.069l9.173.24c1.503.396 2.747 1.181 3.833 2.42.46.525 1.452 2.156 1.452 2.388 0 .02.052.149.115.287.132.289.449 1.344.6 1.994.252 1.093.251 3.681-.003 4.786-.412 1.793-.986 3.103-1.887 4.304-.741.988-1.935 1.99-2.801 2.349-.534.222-1.239.441-1.585.494-.84.128-1.152.148-2.571.17l-1.497.074c0 .164 4.583 4.756 6.946 6.96l1.485 1.443 2.166 2.073c.062.023 10.667-10.571 11.264-11.251.791-.903 1.263-1.691 1.519-2.539.06-.198.124-.381.142-.407.221-.318.167-2.009-.093-2.922-.572-2.009-.51-1.932-5.724-7.138l-7.81-7.928c-.731-.792-4.526-4.584-4.889-4.886l-.407-.34c-.628-.525-1.487-1.11-2.014-1.371-.466-.231-.903-.397-1.226-.466a9.59 9.59 0 0 1-.525-.125c-.159-.048-2.142-.063-2.188-.016z"/>
      <path class={color!==false&&'text-primary'} d="M3.776 17.832C.935 20.656.379 21.61.344 23.713c-.029 1.779.41 3.039 1.572 4.508.277.351 17.529 17.644 17.964 18.007.688.575 1.499 1.036 2.3 1.31.822.281.822.281 2.017.263.891-.014 1.155-.033 1.288-.093a4.87 4.87 0 0 1 .455-.164c.75-.23 1.841-.883 2.587-1.548.535-.478 1.368-1.324 1.355-1.377-.008-.031-1.124-1.183-2.481-2.559l-2.631-2.676a437.2 437.2 0 0 0-2.132-2.163L9.921 24.325c-1.17-1.179-1.817-1.863-1.774-1.877.037-.012 4.207-.031 9.265-.043l9.198-.02.265-.102c1.274-.491 1.654-3.334.698-5.22-.387-.762.192-.727-12.123-.727H5.281l-1.506 1.497z"/>
    </svg>
  ),
  minus: props => (
    <svg {...props} viewBox="0 0 12 12">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M2 6h8"/>
    </svg>
  ),
  phone: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 58 58">
      <path d="M24.017 33.983c-5.536-5.536-6.786-11.072-7.068-13.29a1.99 1.99 0 0 1 .571-1.664L22 14.551a2 2 0 0 0 .282-2.475L15.149 1a2 2 0 0 0-2.591-.729L1.107 5.664a1.99 1.99 0 0 0-1.1 1.987c.6 5.7 3.085 19.712 16.855 33.483s27.78 16.255 33.483 16.855a1.99 1.99 0 0 0 1.987-1.1l5.393-11.451A2 2 0 0 0 57 42.851L45.924 35.72a2 2 0 0 0-2.475.28l-4.478 4.48a1.99 1.99 0 0 1-1.664.571c-2.218-.282-7.754-1.532-13.29-7.068zM46 31a2 2 0 0 1-2-2 15.02 15.02 0 0 0-15-15 2 2 0 1 1 0-4c10.489.012 18.988 8.511 19 19a2 2 0 0 1-2 2zm10 0a2 2 0 0 1-2-2C53.985 15.199 42.801 4.015 29 4a2 2 0 1 1 0-4c16.009.018 28.982 12.991 29 29a2 2 0 0 1-2 2z"/>
    </svg>
  ),
  plus: props => (
    <svg {...props} viewBox="0 0 12 12">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 6h6m-3-3v6" />
    </svg>
  ),
  search: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 12 12">
      <path d="M10.19 11.58L7.784 8.752c-.264-.293-.384-.605-.372-.858a4.46 4.46 0 0 1-2.905 1.071A4.48 4.48 0 0 1 .029 4.488 4.48 4.48 0 0 1 4.506.01a4.48 4.48 0 0 1 4.478 4.478 4.46 4.46 0 0 1-1.071 2.905c.253-.012.565.108.858.372l2.829 2.406c.458.412.497 1.085.086 1.496s-1.084.372-1.496-.086zM7.491 4.488c0-1.649-1.336-2.985-2.985-2.985S1.521 2.839 1.521 4.488s1.336 2.985 2.985 2.985 2.985-1.336 2.985-2.985z"/>
    </svg>
  ),
  spinner: props => (
    <svg {...props} viewBox="-6 -6 12 12">
      <circle fill="none" opacity=".25" stroke="currentColor" r="5" stroke-width="2" />
      <path fill="currentColor" opacity=".75" class="animate-spin" d="M0 0m-4.242640687-4.242640687a6 6 0 0 1 10.242640687 4.242640687h-2a4 4 0 0 0-6.828427125-2.828427125z" />
    </svg>
  ),
  times: props => (
    <svg {...props} viewBox="0 0 12 12">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 9l6-6m-6 0l6 6" />
    </svg>
  ),
  trash: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 16 16">
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
    </svg>
  ),
  profile: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 16 18">
      <path d="M8,0A4.78,4.78,0,0,0,3.2,4.75a4.8,4.8,0,0,0,9.6,0A4.78,4.78,0,0,0,8,0Z" />
      <path d="M15.91,14a7.72,7.72,0,0,0-6.42-3.4h-3A7.72,7.72,0,0,0,.09,14L0,14.15V18H16V14.15Z"/>
    </svg>
  ),
  update: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 16 18">
      <path d="M1,14.49a.63.63,0,0,0,.46.2h.14l4.25-1a.87.87,0,0,0,.33-.17l8.81-9a.63.63,0,0,0,.2-.47.71.71,0,0,0-.2-.47L11.64.2a.65.65,0,0,0-.93,0h0l-8.82,9a.61.61,0,0,0-.17.34L.77,13.88A.66.66,0,0,0,1,14.49Z" />
    </svg>
  ),
  documents: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 19 15">
      <path d="M15,1.35H5.18A.54.54,0,0,1,4.63.81V.55A.55.55,0,0,0,4.07,0H1.12A1.11,1.11,0,0,0,0,1.09v9.18L1.44,3.46A.55.55,0,0,1,2,3H16.1V2.44A1.11,1.11,0,0,0,15,1.35Z" />
      <path d="M17.88,4.1H2.42L.26,14.35A.54.54,0,0,0,.81,15H16.89A1.11,1.11,0,0,0,18,14l1-8.71A1.1,1.1,0,0,0,17.88,4.1Z" />
    </svg>
  ),
  ok: props => (
    <svg {...props} fill="currentColor"  viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12a9 9 0 11 18 0 9 9 0 11-18 0m6 0l2 2 4-4" />
    </svg>
  ),
  fileUpload: props => (
    <svg {...props} fill="currentColor" viewBox="0 0 20 20">
      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
    </svg>
  ),
  questionMark: props => (
    <svg {...props} viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"/>
    </svg>
  ),
};

export default icons;
